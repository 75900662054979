<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar class="px-2">
        <ion-label slot="start" class="fs-3 fw-bold">{{ $t('companies') }}</ion-label>
        <ion-icon class="close-modal" slot="end" :icon="closeOutline" @click="closeModal"></ion-icon>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="!isShow" class="px-2">
      <!-- show-skeleton-loading -->
      <ion-item v-for="(item, index) in 2" :key="index" lines="full" :detail="false" class="skeleton mt-3">
        <ion-thumbnail slot="start">
          <ion-skeleton-text class="avatar" :animated="true"></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 40%; height: 18px"
            ></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 35%; height: 30px"
            ></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 100%; height: 35px"
            ></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 50%; height: 20px"
            ></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
    </ion-content>
    <ion-content v-if="isShow" style="--background: #eeeeee">
      <ion-list v-if="customer" class="pt-2 mb-4">
        <ion-radio-group :value="selectedCompany?.id || previousSelectedCompanyId" mode="md">
          <ion-text class="pl-3 name text-primary">{{ $t('selectCompany.main_company') }}</ion-text>
          <ion-item
            class="card-company mt-2 ion-no-padding mb-3"
            style="margin-right: -4vw"
            lines="full"
            @click="onTapCustomer(customer)"
          >
            <div
              class="image-default mr-2 ml-3"
              :style="`background: ${theme.background}; color:${theme.color}`"
            >
              {{ getInitialName(customer.name) }}
            </div>
            <ion-label style="margin-top: -1px">
              <div class="fw-bold">{{ customer.name }}</div>
              <div class="my-1 text-primary">{{ customer.account_number }}</div>
              <div class="text-grey">{{ customer.address }}</div>
            </ion-label>
            <ion-radio slot="end" :value="customer.id" @ionFocus="onTapCustomer(customer)"></ion-radio>
          </ion-item>

          <div v-if="customer.branches?.length > 0" class="space-grey"></div>
          <div class="pl-3 name text-primary mt-3" v-if="customer.branches?.length > 0">
            {{ $t('selectCompany.branches') }}
          </div>
          <div v-for="company in customer.branches" :key="company.id">
            <ion-item
              lines="full"
              class="card-company mt-2 ion-no-padding mb-3"
              style="margin-right: -4vw"
              @click="onTapCustomer(company)"
            >
              <div
                class="image-default mr-2 ml-3"
                :style="`background: ${themeBranch.background}; color:${themeBranch.color}`"
              >
                {{ getInitialName(company.name ? company.name : company.branch) }}
              </div>
              <ion-label color="dark">
                <div class="fw-bold">{{ company.name ? company.name : company.branch }}</div>
                <div class="my-1 text-primary">{{ company.account_number }}</div>
                <div class="text-grey">{{ company.address }}</div>
              </ion-label>
              <ion-radio slot="end" :value="company.id" @ionFocus="onTapCustomer(company)"></ion-radio>
            </ion-item>
          </div>
        </ion-radio-group>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-toolbar v-if="!isShow" class="my-3 px-2" style="--background: white">
        <ion-label>
          <p>
            <ion-skeleton-text
              class="skeleton-child mb-2"
              :animated="true"
              style="width: 100%; height: 40px"
            ></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 100%; height: 40px"
            ></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-toolbar>
      <ion-toolbar v-else class="mb-3 px-2" style="--background: transparent">
        <ion-button
          :disabled="disableApplyButton"
          class="ion-margin-top foot-btn"
          expand="block"
          @click="applySelectedCustomer"
          >{{ $t('apply') }}</ion-button
        >
        <ion-button
          class="ion-margin-top foot-btn-outline"
          expand="block"
          fill="outline"
          @click="changeCustomer"
          >{{ $t('select_other_company') }}</ion-button
        >
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { handleRamdomBackground } from '@/modules/b2b/services/libs/helper';
import { MUTATIONS } from '@/modules/b2b/store/account/mutations';
import { ACTIONS } from '@/modules/b2b/store/customer/actions';
import { ACTIONS as ACTIONS_NOTIFICATION } from '@/modules/b2b/store/notification/actions';
import { ACTIONS as ACTIONS_PAYMENT } from '@/modules/b2b/store/payment/actions';
import Clevertap from '@/services/shared/helper/clevertap';
import { STORAGE_KEYS } from '@/storage';
import { closeOutline } from 'ionicons/icons';
import { defineComponent, inject } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions: mapActionsPayment } = createNamespacedHelpers('b2b/payment');
const { mapActions: mapActionsNotification } = createNamespacedHelpers('b2b/notification');
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/customer');

const { mapMutations: mapMutationsAccount } = createNamespacedHelpers('b2b/account');

export default defineComponent({
  name: 'branch-selection',
  setup() {
    const ionRouter = inject('navManager');
    return {
      ionRouter
    };
  },
  data() {
    return {
      showChangeButton: true,
      previousSelectedCompanyId: null,
      selectedCompany: null,
      customer: null,
      isShow: false,
      closeOutline,
      theme: [],
      themeBranch: [],
      selectedBranch: null,
      currentPage: null,
      prevPage: null
    };
  },
  props: {
    customerSelect: {
      type: Object,
      default: null
    },
    showChangeBtn: {
      type: Boolean,
      default: false
    }
  },
  inject: ['$storage'],
  async created() {
    this.theme = handleRamdomBackground();
    this.themeBranch = handleRamdomBackground();
  },
  async mounted() {
    this.currentPage = this.$route.path;
    this.prevPage = window.history?.state?.back;
    this.isShow = false;
    this.customer = await this.$storage.getSelectCustomer();
    await this[ACTIONS.GET_CUSTOMERS]({});
    this.showChangeButton = this.showChangeBtn
      ? this.showChangeBtn
      : !this.$route.path.endsWith('/b2b/select-customer');
    let company = await this.$storage.getSelectedCompany();
    this.selectedCompany = company;
    this.previousSelectedCompanyId = company ? company.id : null;
    this.isShow = true;
    let isIncluded =
      this.customer?.branches.length > 0 && this.selectedCompany?.id !== this.customer?.id
        ? this.customer?.branches.some((branch) => branch.id === this.selectedCompany?.id)
        : this.selectedCompany?.id === this.customer?.id
        ? true
        : false;
    if (isIncluded) {
      this.onTapCustomer(this.selectedCompany);
    }
  },
  computed: {
    ...mapGetters(['list']),
    disableApplyButton() {
      let checkBranchSelected = false;
      let checkSelectedMain = this.selectedCompany?.id === this.customer?.id ? true : false;
      if (this.customer?.branches.length > 0 && !checkSelectedMain) {
        checkBranchSelected = this.customer?.branches.some(
          (branch) => branch.id === this.selectedCompany?.id
        );
      } else {
        checkBranchSelected = true;
      }
      return !checkBranchSelected && this.selectedBranch === null;
    }
  },
  emits: ['closeModal', 'resetSearch', 'refreshCart', 'resetPath', 'resetRef'],
  methods: {
    ...mapActions([ACTIONS.UPDATE_SELECTED_CUSTOMER, ACTIONS.GET_CUSTOMERS]),
    ...mapActionsPayment([
      ACTIONS_PAYMENT.GET_LIST_CUSTOMER_CARD,
      ACTIONS_PAYMENT.REMOVE_SELECTED_PAYMENT_INVOICES
    ]),
    ...mapActionsNotification([ACTIONS_NOTIFICATION.GET_BADGE_NUMBERS]),
    ...mapMutationsAccount([MUTATIONS.RENDER_ACCOUNT]),
    getInitialName(name) {
      let initials = '';
      let names = name.split(' ');
      let nameCount = names.length > 2 ? 2 : names.length;
      for (let i = 0; i < nameCount; i++) {
        if (names[i]) {
          initials += names[i][0].toUpperCase();
        }
      }
      return initials;
    },
    async closeModal() {
      await this.$storage.setBackChangeCompany(false);
      this.$emit('closeModal');
    },
    async back() {
      await this.$storage.setBackChangeCompany(false);
      this.$emit('closeModal');
    },
    setOpen(params) {
      this.isShow = params;
    },
    onTapCustomer(value) {
      this.selectedBranch = value;
    },
    async applySelectedCustomer() {
      Clevertap.onUserSelectCompany(this.selectedBranch);
      await this.getNumberBadge(this.selectedBranch?.id);
      if (this.previousSelectedCompanyId && this.previousSelectedCompanyId != this.selectedBranch?.id) {
        await this.getData(this.selectedBranch);
      } else {
        const selectedCompany = this.selectedCompany ? { ...this.selectedCompany } : { ...this.customer };
        selectedCompany.branches = [];
        await this.getData(this.selectedBranch);
      }
      if (this.customer.branches?.length > 0) {
        this.$emit('resetPath');
      }
      this.$emit('resetSearch');
      this.$emit('closeModal');
      this.$emit('resetRef');
      if (this.currentPage === '/b2b/cart' || this.prevPage === '/b2b/cart') {
        this.$emit('refreshCart');
      }
      this.ionRouter.navigate({
        routerLink:
          this.currentPage === '/b2b/cart' ||
          (this.currentPage === '/b2b/select-customer' && this.prevPage === '/b2b/cart')
            ? '/b2b/cart'
            : this.currentPage === '/b2b/invoices/select-invoices' ||
              this.prevPage === '/b2b/invoices/select-invoices'
            ? '/b2b/invoices/select-invoices'
            : '/b2b/main/home'
      });
      this[MUTATIONS.RENDER_ACCOUNT](true);
    },
    async getNumberBadge(customerId) {
      await this[ACTIONS_NOTIFICATION.GET_BADGE_NUMBERS]({
        customerId: customerId
      });
    },
    async getData(value) {
      await this[ACTIONS.UPDATE_SELECTED_CUSTOMER](value);
      // clear address_id
      await this.$storage.remove(STORAGE_KEYS.SELECTED_DELIVERY_ADDRESS);
      await this.$storage.remove(STORAGE_KEYS.SELECTED_BILLING_ADDRESS);

      await this.$storage.setSelectedCompany(value);

      await this.$storage.setSelectedCustomer(this.customer);
      const data = await this.$storage.getSelectCustomer();
      await this[ACTIONS_PAYMENT.GET_LIST_CUSTOMER_CARD](data.id);
    },
    async changeCustomer() {
      await this[ACTIONS_PAYMENT.REMOVE_SELECTED_PAYMENT_INVOICES]();
      // clear address_id
      await this.$storage.remove(STORAGE_KEYS.SELECTED_DELIVERY_ADDRESS);
      await this.$storage.remove(STORAGE_KEYS.SELECTED_BILLING_ADDRESS);

      await this.$storage.setBackChangeCompany(false);
      this.$emit('closeModal');
      this.ionRouter.navigate({
        routerLink: '/b2b/select-customer'
      });
    }
  }
});
</script>

<style scoped lang="scss">
.name {
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
}
ion-label {
  white-space: normal !important;
  font-weight: 400;
  line-height: 22px;
}

#row {
  height: 50px;
  font-size: 20px;
}
.close-modal {
  font-size: 20px;
  color: #92949c;
  background: #989aa226;
  padding: 5px;
  border-radius: 20px;
}
.avatar {
  --border-radius: 9999px;
}
.skeleton-child {
  --border-radius: 8px;
}
.skeleton {
  align-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
.image-default {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14.4px;
  line-height: 17px;
}
.card-company {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.space-grey {
  height: 8px;
  width: 100vw;
  background: #eeeeee;
}
.foot-btn-outline {
  --border-radius: 10px;
  --color: #00676a;
}
.foot-btn {
  --border-radius: 10px;
  --background: #00676a;
  --color: white;
}
.foot-btn[disabled] {
  --border-radius: 10px;
  --background: #9e9e9e;
  --color: white;
}
</style>
